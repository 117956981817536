import React, { useContext, useEffect, useState } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@wizswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import { injected, walletconnect } from 'connectors'
import { usePairContract } from 'hooks/useContract'
import links, { socials } from './config'

const Menu: React.FC = (props) => {
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const [price, setPrice] = useState(0)
  const usdtPwr = "0x217a7522FEda3cea46744645E6329C784606BDC9";
  const contract = usePairContract(usdtPwr)

  useEffect(() => {
    const getPrice = async () => {
      try {
        const reserves = await contract?.getReserves()
        if (reserves) {
          const pricePwr = ((reserves.reserve0 / 10 ** 18) / (reserves.reserve1 / 10 ** 18))
          setPrice(pricePwr)
        }
      } catch (error) {
        // console.log('no liquidity for usdc/awiz');
      }
    }
    getPrice()
    const intervalId = setInterval(() => { 
      getPrice()
    }, 60000)
    return () => clearInterval(intervalId);
  }, [contract])

  return (
    <UikitMenu
      links={links}
      socials={socials}
      account={account as string}
      login={(connectorId: ConnectorId) => {
        if (connectorId === 'walletconnect') {
          return activate(walletconnect)
        }

        return activate(injected)
      }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={price}
      cakePriceLink="#!"
      /* profile={profile} */
      // removeNav
      {...props}
    />
  )
}

export default Menu
