import { MenuEntry } from '@wizswap-libs/uikit'

const config: MenuEntry[] = [
  {
    icon: 'TradeIcon',
    label: 'Swap',
    href: '/',
  },
  {
    icon: 'TradeIcon',
    label: 'Fund LP',
    href: '/pool',
  },
  {
    label: 'Pools',
    icon: 'FarmIcon',
    href: '#',
  },
  {
    label: 'Farming',
    icon: 'FarmIcon',
    href: '#',
  },
  {
    label: 'Bridge',
    icon: 'PoolIcon',
    href: 'https://wizardbridge.org',
  },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'WizSwap',
  //       href: 'https://info.wizswap.finance/token/0x68b24D0AAfC2CdfF9f4379f4bc7252F827Abfe37',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/wizswap/',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/wizswap/',
  //     },
  //   ],
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/wizswap/',
  //     },
  //     // {
  //     //   label: 'Docs',
  //     //   href: 'https://goosedefi.gitbook.io/wiz/',
  //     // },
  //     // {
  //     //   label: 'Blog',
  //     //   href: 'https://wizswap.medium.com/',
  //     // },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IWO',
  //   icon: 'IfoIcon',
  //   href:
  //     'https://google.com',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'ShieldIcon',
  //   href: 'https://certik.org/projects/wiz/',
  // },
]

export default config

export const socials: MenuEntry[] = [
  // {
  //   label: "Github",
  //   icon: "GithubIcon",
  //   href: "https://github.com/wizswap"
  // },
  // {
  //   label: "Docs",
  //   icon: "GitbookIcon",
  //   href: "https://docs.wizswap.finance",
  // },
  // {
  //   label: "Medium",
  //   icon: "MediumIcon",
  //   href: "https://medium.com/@thewizardbridge",
  // },
  {
    label: "Twitter",
    icon: "TwitterIcon",
    href: "https://twitter.com/maxxchain",
  },
  // {
  //   label: "Discord",
  //   icon: "DiscordIcon",
  //   href: "https://twitter.com/maxxchain",
  // },
  {
    label: "Telegram",
    icon: "TelegramIcon",
    href: "https://t.me/maxxchain",
    // items: [
    //   {
    //     label: "English",
    //     href: "https://t.me/wizardbridge",
    //   },
    // {
    //   label: "Announcements",
    //   href: "https://t.me/wizardbridge",
    // },
    // ],
  },
];
